/*eslint-disable */
// import Udf_actionModel from '../../Model/Udf_action'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let UdfActions = {
  /**
   * udf_actionList
   */
  async udf_actionList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "udf_action_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at udf_actionList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * udf_actionView
   */
  async udf_actionView (context, udfActionId) {
    try {      
      let post_data = new FormData();
      post_data.append('action_id', udfActionId);
      return await request.curl(context, "udf_action_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at udf_actionView() and Exception:",err.message)
    }    
  },

  /**
   * udf_actionAdd
   */
  async udf_actionAdd (context, udf_actionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in udf_actionObj) {
        post_data.append(key, udf_actionObj[key]);
      }

      return await request.curl(context, "udf_action_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at udf_actionAdd() and Exception:',err.message)
    }
  },

  /**
   * udf_actionEdit
   */
  async udf_actionEdit (context, udf_actionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in udf_actionObj) {
        post_data.append(key, udf_actionObj[key]);
      }

      return await request.curl(context, "udf_action_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at udf_actionEdit() and Exception:',err.message)
    }
  },

  /**
   * udf_actionDelete
   */
  async udf_actionDelete (context, udfActionId) {
    try{
      let post_data = new FormData();
      
      post_data.append('action_id', udfActionId);

      return await request.curl(context, "udf_action_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at udf_actionDelete() and Exception:',err.message)
    }
  }
}

export {
  UdfActions
}
