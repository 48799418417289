<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmUdf_actionFormData && Object.keys(vmUdf_actionFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationaction_name ">
                    {{cvActionName}}</label
                  >
                  <input
                    v-model="vmUdf_actionFormData.action_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationapi_name">
                    {{cvApiNameLabel}}</label
                  >
                  <input
                    v-model="vmUdf_actionFormData.api_name"
                    type="text"
                    class="form-control"
                    required
                  />
                  </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_name">
                    {{cvModuleNameLabel}}</label
                  >
                  <select v-model="vmUdf_actionFormData.module_name" class="form-control">
                  <option value="ORG">ORG</option>
                  <option value="UNIV">UNIV</option>
                  <option value="CLUB">CLUB</option>
                  <option value="SCH">SCH</option>
                </select>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="udf_actionEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { UdfActions } from "../../../FackApi/api/udfAction.js"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "UdfActionEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUdf_actionObj: {
      type: Object,
      default: function () {
        return {
          "action_name": "",
          "api_name": "",
          "module_name": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit udf_action",
      cvCardSubHeader: "Edit udf_action sub header",
      cvSubmitBtn: "Edit",
      cvActionName: "action name ",
      cvApiNameLabel: "api name",
      cvModuleNameLabel: "module name",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Udf_action Updated",
      vmUdf_actionFormData: {}
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.udf_actionView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUdf_actionFormData) {
          if (!this.vmUdf_actionFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * udf_actionView
     */
    async udf_actionView () {
      try {
        if (this.propOpenedInModal) {
          this.vmUdf_actionFormData = this.propUdf_actionObj
        }
        else {
          let udfActionId = this.$route.params.action_id
          let udfActionViewResp = await UdfActions.udf_actionView(this, udfActionId)

          if (udfActionViewResp && udfActionViewResp.resp_status) {
            this.vmUdf_actionFormData = udfActionViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at udf_actionView() and Exception:", err.message)
      }
    },
    /**
     * udf_actionEdit
     */
    async udf_actionEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let udfActionAddResp = await UdfActions.udf_actionEdit(this, this.vmUdf_actionFormData)
        await ApiResponse.responseMessageDisplay(this, udfActionAddResp)

        if (udfActionAddResp && !udfActionAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUdf_actionEditModal", this.vmUdf_actionFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at udf_actionEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
